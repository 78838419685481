import baseStyles from '../sass/base.scss';
import selectricJS from './selectric/jquery.selectric.min.js';
import mobileMenuJS from './includes/mobile_menu.js';
import fancyboxJS from './fancybox/jquery.fancybox.min.js';
import customFancyboxJS from './includes/custom_fancybox.js';
import customScrollsJS from './includes/custom_scrolls.js';
import commonSliderJS from './includes/common_slider.js';
import customTabsJS from './includes/custom_tabs.js';
import faqJS from './includes/custom_faq.js';
import subMenuJS from './includes/mobile_sub_menu.js';

$(document).ready(function() {
    $('.selectric-select').selectric({
        maxHeight: 360,
        disableOnMobile: false,
        nativeOnMobile: true
    });
});

