import PerfectScrollbar from 'perfect-scrollbar';
import perfectScrollbarStyles from '../../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';

(function( $ ) {

    $.fn.customScroll = function() {

        var jqAllAffectedContainers = this;
        var ps = null;

        jqAllAffectedContainers.each(function() {
            ps = new PerfectScrollbar(this, {
                maxScrollbarLength: 50,
            });
        });

        return ps;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.custom-scroll.scroll-standalone').customScroll();
});
