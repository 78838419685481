
(function( $ ) {

    $.fn.mobileSubMenu = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContaienr = $(this);
            var actionClose = jqContaienr.find('.sub-menu-js-action .close');
            var actionBack = jqContaienr.find('.sub-menu-js-action .back');
            var subMenuItems = jqContaienr.find('.sub-menu-js-menu-body .has-sub-menu');
            subMenuItems.each(function() {
                var subMenuItem = $(this);
                var openLink = subMenuItem.find('>a').first();
                openLink.click(function(e) {
                    e.preventDefault();
                    subMenuItem.addClass('open');
                    actionClose.addClass('closed');
                    actionBack.removeClass('closed');
                })
            });
            actionBack.click(function(e) {
                e.preventDefault();
                subMenuItems.each(function() {
                    var subMenuItem = $(this);
                    subMenuItem.removeClass('open');
                });
                actionClose.removeClass('closed');
                actionBack.addClass('closed');
            })
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.sub-menu-js').mobileSubMenu();
});
