function customFancyboxInit(fancyboxLinkNode) {
	if (fancyboxLinkNode === undefined || !fancyboxLinkNode) {
        console.error('customFancyboxInit: invalid fancyboxLinkNode ', fancyboxLinkNode);
        return false;
    }
	let fancyboxContentId = fancyboxLinkNode.getAttribute("href");
	if (!fancyboxContentId || fancyboxContentId.length === 0) {
		console.error('customFancyboxInit: invalid fancyboxContentId from href attribute ', fancyboxLinkNode);
		return false;
	}
	var ps = null;
	fancyboxLinkNode.addEventListener('click', function(e) {
		$.fancybox.open({
			src  : fancyboxContentId,
			type : 'inline',
			opts : {
				buttons: [],
				baseClass: "custom-fancybox",
				afterShow : function( instance, current ) {
					if ($('.custom-scroll.scroll-fancybox').hasClass('ps')) {
						if (ps !== null) ps.update();
					} else {
						ps = $('.custom-scroll.scroll-fancybox').customScroll();
						ps.update();
					}
				},
				beforeShow: function( instance, current ) {
					$('.common-item-slider-holder').slick('refresh');
				}
			}
		});
	});
}

$("body").on("click", ".closePopUp", function(){
	$.fancybox.close();
	return false;
});

document.addEventListener('DOMContentLoaded', function() {
    const fancyboxLinkNodes = document.getElementsByClassName('custom-fancybox');
    for(let i = 0; i < fancyboxLinkNodes.length; i++) {
        let fancyboxLinkNode = fancyboxLinkNodes.item(i);
        const customFancyboxObj = new customFancyboxInit(fancyboxLinkNode);
    }
});
